<template>
  <div class="order">
    <div class="contain">
      <div class="top flex">
        <div class="flex1 flex flex_col m_l_04 p_lr_10">
          <div class="flex">
            <div class="flex1 f30 bold txt_light_3 flex">{{ courseInfo.courseName }}</div>
            <div class="flex algin" @click="collection(courseInfo.id)">
              <el-image
                  class="scang"
                  v-if="courseInfo.isS == 0"
                  :src="shoucang"
              ></el-image>
              <el-image
                  class="scang"
                  v-else
                  :src="shoucangSelect"
              ></el-image>
              <div v-if="courseInfo.isS == 1" class="txt_light m_l_02">已收藏</div>
              <div v-else class="txt_light m_l_02">收藏</div>
            </div>
          </div>
          <div class="video"></div>
          <div class="flex f25 txt_light_6 p_tb_08"><span>讲师：</span>{{courseInfo.teachName}}</div>
        </div>
      </div>
      <!--      简介：-->
      <el-tabs v-model="activeName" @tab-click="handleClick" class="bg_white p_lr_10">
        <el-tab-pane label="课程简介" name="first">
            <div class="p_in_08">{{courseInfo.introduce}}</div>
        </el-tab-pane>
        <el-tab-pane label="优秀提问" name="second">
          <el-card class="box-card">
            <div v-for="o in 4" :key="o" class="text item p_in_05">
              <div class="flex algin">
                <div class="flex flex1 algin">
                  <el-image
                      class="tx-img"
                      :src="shoucangSelect1"
                  ></el-image>
                  <div class="txt_light  f25">{{'列表内容 ' + o }}</div>
                </div>
                <div class="txt_light_6 p_lr_10">2020-10-10</div>
              </div>
              <div class="txt_light_3">{{'列表内容 ' + o }}</div>
            </div>
          </el-card>
        </el-tab-pane>
      </el-tabs>

    </div>
  </div>

</template>

<script>
import shoucangSelect1 from '@/assets/img/avatar_01.gif'
import shoucang from '@/assets/img/shoucang.png'
import shoucangSelect from '@/assets/img/shoucang_select.png'
export default {
  name: "record",
  data(){
    return {
      shoucangSelect1,
      shoucang,
      shoucangSelect,
      courseInfo:{},
      activeName: 'first'
    }
  },
  created() {
    this.getCourseInfo()
  },
  methods:{
    getCourseInfo(){
      this.courseInfo = {"id":'1',"courseName":'硬笔书法，学写一手好字',"teachName":'田舍之老师','introduce':"田舍之老师，中国书法加田舍之老师，中国书法加田舍之老师，中国书法加田舍之老师，中国书法加田舍之老师，中国书法加田舍之老师，中国书法加田舍之老师，中国书法加田舍之老师，中国书法加田舍之老师，中国书法加田舍之老师，中国书法加田舍之老师，中国书法加田舍之老师，中国书法加",'isS':'0',}
    },
    // 点击收藏
    collection(item){

      console.log(item)
      // this.getCourseInfo()
    },

    handleClick(tab, event) {
      console.log(tab, event);
    }
  },

}
</script>

<style scoped>
.order{width: 80%;margin: 0 auto;margin-top: 2em;}
.w100{width: 100%;}
.flex{display: flex;}
.flex_col{flex-direction: column}
.flex_end{justify-content: flex-end;}
.flex1{flex: 1;}
.f30{font-size: 2em;}
.f25{font-size: 1.5em}
.f20{font-size: 1em}
.bg{background: #21a59b;}
.bg1{background: #eeeeee;}
.txt_white{color: #fff}
.p_in_08{padding: 0.8em; box-sizing: border-box;}
.p_in_05{padding: 0.5em; box-sizing: border-box;}
.p_tb_08{padding-top: 0.8em;padding-bottom: 0.8em;box-sizing: border-box;}
.bold{font-weight: bold;}
.txt_light_3{color: #555;}
.txt_light_6{color: #888;}
.text-justify{text-align: justify}
.m_l_04{margin-left: 1.4em;}
.top{width: 100%;margin:0 auto;padding: 20px;box-sizing: border-box;min-height: 300px;background: #fff}
.img-box{width:35%;height: 280px;border: 1px solid #eee;}
.image{display: block;width: 100%;height: 100%;object-fit: contain}
.contain{width: 80%;margin: 0 auto;}
.txt_light{color: #666}
.scang{display:flex;width: 18px;height: 18px;}
.m_l_02{margin-left: 0.2em}
.bg_light{background: #ddd;}
.fontS{width: 69%;line-height: 2em;text-indent: 2em;}
.algin{align-items: center}
.bg_white{background: #fff;border: 1px solid #eee}
.video{background: #eee;width: 90%;height: 40em;margin-top: 2em;}
.p_lr_10{padding-left: 10px;padding-right: 10px;box-sizing: border-box;}
.tx-img{width: 3em;height: 3em}
</style>
